import React from 'react'

const NextStep = () => {
    return (
        <div>
            <div className="container  space-bottom">
                <div className="inner-smallspace-ganerate-code">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-page-design">
                                <h2>Alternative Method of Entry</h2>

                                <p>
                                    1. In order for your request for 3.00 Saga Coins to be processed, you must precisely follow these steps below:
                                    <ul>
                                        <li>
                                            Please send us a standard postcard or a single piece of white paper, this will be your Saga Coins Request Card. Colored or ruled paper will not be accepted.
                                        </li>
                                        <li>
                                            This should be placed inside an individually stamped #10 envelope with the handwritten address exactly as below. Do not print the address.
                                        </li>
                                    </ul>
                                </p>
                                <p>SpinSaga Casino</p>
                                <p>MW SERVICES LIMITED</p>
                                <p>1445 WOODMONT LN NW #720</p>
                                <p>ATLANTA, GA 30318 U.S.A.</p><br />
                                <p>All users sending in Saga Coins Request Cards must:
                                    <ul>
                                        <li>Hand-write in CAPS and use only BLACK INK.</li>
                                        <li>On the front of the envelope, write your return address and the exact words “Request for Sweepstakes Credits”* </li>
                                    </ul>
                                </p>
                                <p>On one side of the Saga Coins Request Card, write:</p>
                                <p>
                                    <ol>
                                        <li>Full name exactly as shown on government issued ID.</li>
                                        <li>The email address associated with your SpinSaga Casino account.</li>
                                        <li>The residential address associated with your SpinSaga Casino account.</li>
                                        <li>The 16 digit unique request code that you generated on the previous page.</li>
                                        <li>The statement exactly as laid out below:</li>
                                    </ol>
                                </p>
                                <p>“I wish to receive free Saga Coins in order to participate in the sweepstakes promotions offered by SpinSaga Casino.In submitting this written request, I confirm that I have read, understood and agree to be bound by the Terms and Conditions and Sweepstakes Rules as set out on the Spin Saga Casino website”</p>
                                <p>2: Each individual outer envelope is limited to include a maximum of one (1) Unique Sweepstakes Request Card Request. Envelopes with more than one request will be rejected and all requests included will be void. For each Request Card an Eligible Participant submits correctly, in accordance with the above requirements, the Eligible Participant will receive three (3) Saga Coins. The Saga Coins will be credited directly to the Eligible Participant’s Customer Account.</p>
                                <p>3: You must ensure that your handwriting is legible. If your handwriting is deemed to be illegible, your entry will be void and the Saga Coins will not be credited to your customer account.The legibility of handwriting on the Saga Coins Request Cards shall be decided by our mail processing partner in its sole discretion.</p>
                                <p>4: You must not electronically copy or duplicate any Sweepstakes Coins Request Card. Any duplicate requests will render all requests void.</p>
                                <p>5: All request cards which do not comply with the above rules will not be acknowledged or returned to sender.</p>
                                <p>6: Saga Coins requests must only be made by the individual associated with a unique customer account.Requests made by any other individual or entity will be declared invalid and the Saga Coins will not be credited to the customer account. <br />
                                    Tampering with the Saga Coins request process or the operation of the Sweepstakes, including but not limited to the use of any device to automate the Saga Coins request or entry process, is strictly prohibited and any requests or entries deemed by us, at our sole discretion, to have been submitted using such will be declared null and void. In the event that a dispute regarding the identity of an individual who submitted a request cannot be successfully resolved to the our satisfaction, the request in dispute will be deemed invalid.</p>
                                <p>7: As per the sweepstakes rules, the amount of Saga Coins credited will be displayed in your customer account on the SpinSaga Casino website.</p>
                                <p>8: The actual amount of Saga Coins allocated via the AMOE / Saga Coins Request Card can be changed at any time by the sponsor at its sole discretion.</p>
                                <p>9: The sponsor is not responsible for any Saga Coins Request Cards that are incomplete, invalid, lost or misdirected.</p>
                                <p>10:A Unique One-Time Request Code comprises a unique combination of 16 digits which can only be used once.</p>
                                <p>
                                    <ol>
                                        <li>All Eligible Participants can obtain Unique One-Time Request Codes by following the steps outlined in the Sweepstakes Rules. This code assists with the accurate and efficient processing of Saga Coins Request Cards. Each Unique One-Time Request Code is valid only for use by the individual customer whose Spin Saga Casino customer account was used to generate the code whilst logged into the Spin Saga Casino website. The sponsor/promoter reserves the right, in its sole discretion, to disqualify from promotional sweepstakes and/or deactivate the customer account of any individual who is deemed to have provided or received a Unique One-Time Request Code to or from another individual.</li>
                                        <li>The sponsor/promoter reserves the right, in its sole discretion, to disqualify from promotional sweepstakes and/or deactivate the customer account of any individual who is deemed to have tampered with or attempted to alter the process in which a Unique One-Time Request Code is generated during our alternative method of entry process on the Spin Saga Casino website.</li>
                                        <li>A Unique One-Time Request Code is generated by following the steps below:
                                            <ul>
                                                <li>Sign in to your SpinSaga Casino customer account</li>
                                                <li>Click the option for “INSTRUCTIONS ON ALTERNATIVE METHOD OF ENTRY”</li>
                                                <li>follow the process to generate your Unique One-Time Request Code and save it for use on your Saga Coins Request Card.</li>
                                            </ul>
                                        </li>
                                        <li>Saga Coins requests must only be made by the individual associated with a unique customer account. Requests made by any other individual or entity will b</li>
                                    </ol>
                                </p>
                            </div>
                            <div>


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NextStep