import React from "react";
import { isMobile } from "react-device-detect";

const PrivacyPolicy = () => {
  return (
    <div>
      <div
        className="container bg-color-pp space-bottom content-only"
        style={{ marginTop: 80}}
      >
        <div className="inner-smallspace-rg">
          <div className="row">
            <div className="col-md-12">
              <div className="content-page-design" style={{ padding: "20px",marginLeft:isMobile?"0px":"15px" }}>
                <h2>SpinSaga Privacy Policy</h2>
                <h4>Effective Date: 6 Dec 2024</h4>

                <div>
                <p>
                  At SpinSaga Inc. (“SpinSaga,” “Company,” “we,” “us,” or
                  “our”), we are committed to maintaining transparency and
                  protecting the privacy of our customers. SpinSaga Inc.
                  operates the SpinSaga website (
                  <a href="https://www.spinsagacasino.com/">
                    https://www.spinsagacasino.com/
                  </a>
                  ), along with any subdomains, subpages, successor sites, or
                  applications accessible via our platform (collectively
                  referred to as the “Website” or “Services”).
                </p>

                <p>
                  We value the trust and confidence of our users and are
                  dedicated to protecting and respecting your privacy. This
                  Privacy Policy (“Privacy Policy”) outlines how SpinSaga Inc.
                  collects, uses, maintains, and shares information gathered
                  through the Website.
                </p>

                <p>
                  By accessing or using the Services, you are agreeing to this
                  Privacy Policy. If you do not agree with our policies and
                  practices, your only option is to not use the Services. As
                  stated below, this policy may change periodically. Please
                  check the policy for updates and continued use of the Services
                  after changes are made will be considered acceptance of those
                  changes. Please read this policy carefully to understand how
                  we handle your data.
                </p>

                <h3>1. Policy Overview</h3>
                <p>
                  Our commitment to protecting the privacy of individuals who
                  visit our Site (“Visitors”) and users who have registered for
                  an account (“Players”) is outlined in this Policy. This policy
                  applies to information that is automatically collected via the
                  Services or that is provided to us by Visitors or Players.
                </p>

                <p>However, this Privacy Policy does not apply to:</p>
                <ul>

                <p>
                  <li>
                    <strong>Offline Information:</strong> Information collected
                    by us offline or through any other means, including on any
                    other website operated by SpinSaga or any third party
                    (including our affiliates and subsidiaries) is not subject
                    to this Privacy Policy.
                  </li>
                </p>
                <p>
                  <li>
                    <strong>Third Parties:</strong> We are not responsible for
                    the privacy policies or other practices employed by websites
                    linked to, or from, our Site, nor the information or content
                    contained therein. We encourage you to read the privacy
                    statements of any linked third party.
                  </li>
                </p>
                </ul>

                <p>
                  This Privacy Policy is a legally binding contract between the
                  Operator and anyone who accesses or uses the Services. By
                  using the Services, you are agreeing to be bound by the terms
                  of this Privacy Policy, which may be amended from time to
                  time, and to the collection, storage, use, and protection of
                  personal information as described herein. If you do not wish
                  to be bound by this Privacy Policy, please discontinue use of
                  the Services immediately.
                </p>

                <p>
                  SpinSaga reserves the right to amend this Privacy Policy and
                  the Terms and Conditions from time to time at our sole
                  discretion. We will notify you of any material change by
                  posting the revised Privacy Policy on the Services. Any
                  changes to the Privacy Policy will be effective immediately
                  upon notification. For any non-material change that does not
                  require notification, your continued use and access to the
                  Services after such change or amendment will be deemed as
                  acceptance of such change or amendment. We encourage you to
                  visit this Privacy Policy from time to time to ensure that you
                  are aware of the current version and any changes that we may
                  make.
                </p>

                <h3>1.Definitions</h3>
                <p>
                  <ul>
                    <p>
                      <li>
                        <strong>Personal Data:</strong> Any information that
                        identifies or can identify you as a natural person.
                        Examples include your name, email address, or IP
                        address.
                      </li>
                    </p>
                    <p>
                      <li>
                        <strong>Non-Personal Data:</strong> Data that cannot
                        identify you directly or indirectly. Non-Personal data
                        is not considered personal data under applicable laws.
                      </li>
                    </p>
                    <p>
                      <li>
                        <strong>Special Categories of Data:</strong> Sensitive
                        data, such as information about health, race, or
                        religious beliefs, which SpinSaga does not collect.
                      </li>
                    </p>
                  </ul>
                </p>

                <h3>2. Information We Collect</h3>

                <h4>2.1 Information Collected</h4>
                <ul>
                  <p>
                    At SpinSaga, we collect a variety of information about our
                    Visitors and Players, including:
                  </p>

                  <p>
                    Personal Data, which may include your name, email address,
                    phone number, or any other identifier that may be used to
                    contact you online or offline;
                  </p>
                  <p>
                    Non-Personal Data, which may be information about you but
                    does not identify you individually; and
                  </p>
                  <p>
                    Usage details, information about your internet connection
                    and the equipment you use to access our Services.
                  </p>
                  <p>
                    Location of you or your device at the time of accessing
                    certain services or sections of the site or app.
                  </p>
                  <p>
                    We collect this information from you directly when you
                    provide it to us, automatically as you navigate through the
                    Services, or from third parties such as our business
                    partners.
                  </p>
                </ul>

                <h4>2.2 Information That You Provide Directly to Us</h4>
                <ul>
                  <p>
                    When you register to use our Services or request certain
                    materials, we may require you to provide Personal Data, such
                    as your name, email address, phone number, driver's license,
                    birth date, gender, and unique identifiers, like a username
                    or password. We may also receive Personal Data from records
                    and copies of your correspondence, dialog boxes or chats,
                    your responses to surveys or feedback requests, or details
                    of transactions you conduct through our Services.
                  </p>
                  <p>
                    Additionally, we may obtain Personal Data if you choose to
                    publish or display information (referred to as “User
                    Contributions”) in public areas of our Services or transmit
                    it to other Players or third parties. Your User
                    Contributions are posted and transmitted to others at your
                    own risk, and we cannot control the actions of other Players
                    with whom you choose to share your User Contributions. As a
                    result, we cannot guarantee that your User Contributions
                    will not be viewed by unauthorized persons.
                  </p>
                  <p>
                    Our Services collect real-time information about the
                    location of your device. Disabling location data or
                    falsifying your location will impair the functions of
                    Services, including prohibiting you from entering contests,
                    depositing and withdrawing funds.
                  </p>
                </ul>

                <h4>2.3 Geolocation Data</h4>
                <ul>
                  <p>
                    If you use location-based services, we may collect, process,
                    maintain, and use your location data to provide and enhance
                    such services. By using these services, you consent to this
                    data usage. If you wish to withdraw your consent, you may
                    turn off location settings or inform us in writing. However,
                    please note that if you do so, your access to and use of the
                    Services may be restricted, blocked, or terminated.
                  </p>
                </ul>

                <h3>3. How We Use Your Information</h3>
                <p>The data we collect is used for the following purposes:</p>

                <ul>
                  <li>
                    <p>
                      <strong>Service Delivery:</strong> To provide access to our games, process
                      transactions, and manage your account.
                    </p>
                  </li>
                  <li>
                    <p>
                    <strong>Personalization:</strong> To tailor offers, promotions, and content
                      to your preferences.
                    </p>
                  </li>
                  <li>
                    <p>
                    <strong>Security and Compliance:</strong> To maintain the integrity of our
                      platform and comply with applicable laws.
                    </p>
                  </li>
                  <li>
                    <p>
                    <strong>Customer Support:</strong> To address inquiries, process refunds,
                      and resolve disputes.
                    </p>
                  </li>
                  <li>
                    <p>
                    <strong>Marketing:</strong> To send promotional messages, subject to your
                      consent. You can opt out of the services by informing us.
                    </p>
                  </li>
                </ul>

                <h3>4. Sharing Your Information</h3>
                <p>
                  SpinSaga may use aggregated (anonymized) information about its
                  users and non-personal information without restriction.
                </p>
                <p>
                  We will limit the collection of your Personal Data to the
                  extent necessary for the purposes identified by SpinSaga.
                  Unless you consent or we are required by law, we will only use
                  the Personal Data for the purposes for which it was collected.
                  If SpinSaga processes your Personal Data for another purpose
                  later on, SpinSaga will seek your further legal permission or
                  consent, unless the other purpose is compatible with the
                  original purpose. We will retain your Personal Data only for
                  as long as necessary to serve those purposes and will also
                  retain and use your Personal Data as necessary to comply with
                  our legal obligations and resolve disputes. We will share your
                  data only:
                </p>

                <ul>
                  <li>
                    <p>
                      <strong>With Service Providers:</strong> Trusted partners
                      that assist with payment processing, marketing, or
                      technical support.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>For Legal Compliance:</strong> To comply with
                      regulatory or legal obligations.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>With Your Consent:</strong> When you explicitly
                      agree to data-sharing for specific services or
                      partnerships.{" "}
                    </p>
                  </li>
                </ul>

                <h4>4.1 Data Transfers Outside Jurisdictions</h4>
                <ul>
                <p>
                  If we transfer data outside your jurisdiction, we ensure
                  adequate safeguards, such as Standard Contractual Clauses, to
                  protect your privacy.
                </p>
                </ul>

                <h3>5. Your Privacy Rights</h3>
                <p>
                  As a user, you have the following rights under applicable
                  privacy laws:
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>Access and Correction:</strong> Request access to
                      your personal data and correct inaccuracies.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Data Deletion:</strong> Request the deletion of
                      your personal data, subject to legal retention
                      requirements.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Opt-Out of Sale or Sharing:</strong> Opt-out of
                      the sale or sharing of your personal information for
                      targeted advertising.
                    </p>
                  </li>
                </ul>

                <p>
                  To exercise your rights, contact us at{" "}
                  <a href="mailto:support@spinsagacasino.com">support@spinsagacasino.com</a>
                </p>

                <h3>6. Data Security</h3>
                <p>
                  We prioritize the security of your data by employing
                  industry-standard encryption, firewalls, and secure data
                  storage. Despite our best efforts, no system is entirely
                  secure, and we encourage you to maintain strong password
                  practices.
                </p>

                <h3>7. Age Restrictions</h3>

                <p>
                  Our services are intended for users aged 18 and older. We do
                  not knowingly collect or process information from individuals
                  under this age.
                </p>

                <h3>8. Changes to the Privacy Policy</h3>

                <p>
                  We reserve the right to update or modify this Privacy Policy
                  at any time and without prior notice. Any changes to this
                  Privacy Policy will be posted on this page with a notice that
                  the Privacy Policy has been updated on the Site home page. If
                  we make any material changes to how we treat your Personal
                  Data, we will notify you by email to the primary email address
                  specified in your account, through a prominent notice on the
                  Site home page. The revised Privacy Policy will be effective
                  immediately upon posting, and the date of the last revision
                  will be identified at the top of this page.
                </p>

                <p>
                  You are responsible for ensuring that we have an up-to-date,
                  active, and deliverable email address for you and for
                  periodically reviewing this Privacy Policy to be informed of
                  any changes. Your continued use of our Services after the
                  posting of any amendment, modification, or change to this
                  Privacy Policy constitutes your acceptance of the amendments
                  to this Privacy Policy. If you do not accept the terms of this
                  Privacy Policy or any modified version of this Privacy Policy,
                  you may choose to discontinue the use of the Services.
                </p>

                <h3>9. Contact Us</h3>
                <p>For privacy-related inquiries:</p>
                <p>Email: support@spinsagacasino.com</p>
                <p>
                  Mailing Address: SPINSAGA INC. 34 N Franklin Ave, Suite 687,
                  Pinedale, WY, 82941.
                </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
