import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

const GenerateCode = () => {
  const [generateCode, setGenerateCode] = useState("");
  const [isActive, setIsActive] = useState(false);
  const theme = useTheme();

  const generateRandom = () => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomString = "";
    for (let i = 0; i < 16; i++) {
      let randomIndex = Math.floor(Math.random() * chars.length);
      randomString += chars[randomIndex];
    }
    setIsActive(true);
    setGenerateCode(randomString);
  };
  return (
    <div>
      <div className="container  space-bottom">
        <div className="inner-smallspace-ganerate-code">
          <div className="row">
            <div className="col-md-12">
              <div className="content-page-design">
                <h2>Alternative Method of Entry</h2>

                <p>
                  To gain access to the 3.00 Sweepstakes coins offer, please
                  follow the detailed verification process below. This is to
                  ensure a high level of security and maintain the exclusivity
                  of this promotion.
                </p>
                <p>GENERATE YOUR UNIQUE REQUEST CODE</p>
                <p>
                  Please click the button below to generate your unique one time
                  request code.
                </p>
                <p>
                  A 16 digit code will will be generated and will be available
                  to claim exclusively against your account.{" "}
                </p>
                <p>
                  Please take a note of the code as you will need this on the
                  next step. You will not be able to access this code again.
                </p>
                <p>
                  Note: Each code can only be used for one coin request. Only
                  codes generated legitimately via this page will be eligible
                  for coin requests. To ensure you have time to note down your
                  code, a new code cannot be generated for a period of 5 mins.{" "}
                </p>
              </div>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <div className="get_code">
                  {generateCode && (
                    <>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ mt: 2, mb: 2 }}
                      >
                        Your code:
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{ mt: 2, mb: 2 }}
                      >
                        {generateCode}
                      </Typography>
                      <Box display="flex" justifyContent="center" width="100%">
                        <Button
                          component={Link}
                          to="/next-step"
                          variant="contained"
                          color="error"
                          className="nav-button-custom"
                          sx={{
                            pr: 7,
                            pl: 7,
                            backgroundColor:
                              theme.palette.custom.redblue_border,
                          }}
                        >
                          Next Step
                        </Button>
                      </Box>
                    </>
                  )}
                </div>
              </Box>
              <div className="bottom_btn">
                <Box display="flex" justifyContent="center" alignItems="center">
                  {!generateCode && (
                    <Button
                      onClick={generateRandom}
                      variant="contained"
                      color="error"
                      className={`nav-button-custom ${isActive ? "hide" : ""}`}
                      sx={{ pl: 5, pr: 5, pt: 1, pb: 1, mt: 4 ,         backgroundColor:
                        theme.palette.custom.redblue_border,}}
                    >
                      Generate Code
                    </Button>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateCode;
