import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbarContext } from "../../component/SnackbarContext";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { useNavigate } from "react-router-dom";
import { endPoints } from "../../constant/Environment";
import { addData } from "../../Utility/API";
import { useAuth } from "../../AuthContext";
import { isMobile } from "react-device-detect";

const ResponsibleGamingsettings = () => {
  const authState = useSelector((state) => state.auth);
  const { IsLoginData } = authState;
  const [show, setShow] = useState(false);
  const [cooloff, setCooloff] = useState(false);

  const dispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const [cooloffperiod, setcooloffperiod] = useState("");
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const closeCool = () => setCooloff(false);

  const openCool = () => {
    if (!cooloffperiod) {
      showErrorSnackbar("Please enter the time period");
      return;
    }
    setCooloff(true);
  };

  const currencies = [
    {
      value: "1",
      label: "1 Days",
    },
    {
      value: "3",
      label: "3 Days",
    },
    {
      value: "7",
      label: "7 Days",
    },
    {
      value: "14",
      label: "14 Days",
    },
    {
      value: "30",
      label: "30 Days",
    },
  ];

  const handleCooloff = async () => {
    let data = {
      id_user: IsLoginData?.idUser,
      time_period: cooloffperiod ? parseInt(cooloffperiod) : "",
      isActive: true,
    };
    // if (!cooloffperiod) {
    //   showErrorSnackbar("please enter the time period");
    //   return;
    // }
    dispatch(setLoading(true));
    let url = `${endPoints.api.USER_COOLOFF}`;
    let result = await addData(url, data);
    try {
      if (result.data.status === "success") {
        showSuccessSnackbar(result?.data?.message);
        dispatch(setLoading(false));
        logout();
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleExclude = async () => {
    let data = {
      id_user: IsLoginData?.idUser,
      isActive: true,
    };
    let url = `${endPoints.api.USER_EXCLUDE}`;
    dispatch(setLoading(true));
    let result = await addData(url, data);
    try {
      if (result.data.status === "success") {
        showSuccessSnackbar(result?.data?.message);
        logout();
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
       {/* exclude Dialog */}
      <Dialog
        open={show}
        onClose={handleClose}
        disableEscapeKeyDown
        PaperProps={{
          style: { backgroundColor: "white" },
        }}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            style={{ color: theme.palette.custom.black_light }}
          >
            <strong>Confirm Exclusion</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="h6"
            style={{ color: theme.palette.custom.black_light }}
          >
            Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
        >
          {/* Cancel Button */}
          <Button
            style={{
              background: theme.palette.custom.red_gradient,
              marginRight: "5px",
            }}
            variant="secondary"
            onClick={handleClose}
          >
            No
          </Button>

          {/* Confirm Exclusion Button */}
          <Button
            style={{ background: theme.palette.custom.greenbtn }}
            variant="danger"
            onClick={handleExclude}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

       {/* cool off Dialog */}
      <Dialog
        open={cooloff}
        onClose={closeCool}
        disableEscapeKeyDown
        PaperProps={{
          style: { backgroundColor: "white" },
        }}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            style={{ color: theme.palette.custom.black_light }}
          >
            <strong>Confirm Cool Off</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="h6"
            style={{ color: theme.palette.custom.black_light }}
          >
            Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              background: theme.palette.custom.red_gradient,
              marginRight: "5px",
            }}
            variant="secondary"
            onClick={closeCool}
          >
            No
          </Button>
          <Button
            style={{ background: theme.palette.custom.greenbtn }}
            variant="danger"
            onClick={handleCooloff}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h6">Responsible Gaming Settings</Typography>
      <Grid container spacing={4} sx={{ marginTop: 1, paddingBottom: 4 }}>
        {/* Cool Off Period Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: theme.palette.custom.blue_bg,
              p: 3,
              borderRadius: 2,
            }}
            height={isMobile?420:350}
          >
            <Typography variant="h6" gutterBottom>
              Cool Off Period:
            </Typography>
            <Typography variant="body1">
              Please select the number of days you would like to take a break
              from playing at SpinSaga Casino. You will not be able to access
              your account or any games during this period.
            </Typography>
            <Typography variant="body2" sx={{ pt: 1 }}>
              We will not be able to remove this limit once set.
            </Typography>

            <Box sx={{ mt: 2 }}>
              <FormLabel
                htmlFor="numberOfDays"
                sx={{ display: "block", mb: 1 }}
              >
                Period:
              </FormLabel>
              <FormControl fullWidth variant="outlined">
                <Select
                  id="numberOfDays"
                  value={cooloffperiod}
                  onChange={(e) => setcooloffperiod(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Number of Days
                  </MenuItem>
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{
                  background: theme.palette.custom.greenbtn,
                  color: theme.palette.custom.white,
                  textTransform: "capitalize",
                }}
                onClick={openCool}
              >
                Set Cool Off
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Self Exclusion Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: theme.palette.custom.red_light,
              p: 3,
              borderRadius: 2,
            }}
            height={isMobile?420:350}
          >
            <Typography variant="h6" gutterBottom>
              Self Exclusion:
            </Typography>
            <Typography variant="body1">
              If you have a gambling problem or feel you are unable to control
              your play, we suggest you opt to self exclude.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Self Exclusion will remove access immediately from the site and
              cannot be reversed. Any open balances will be returned to you.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              We are at liberty to advise certain regulatory bodies of your self
              exclusion.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                color: theme.palette.custom.white,
                fontWeight: "bold",
              }}
            >
              This cannot under any circumstances be reversed. It is a permanent
              exclusion from SpinSaga.
            </Typography>

            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpen}
                sx={{
                  background: theme.palette.custom.greenbtn,
                  color: theme.palette.custom.white,
                  textTransform: "capitalize",
                }}
              >
                Self Exclude from SpinSaga
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ResponsibleGamingsettings;
