export const GeoComplyKey = {
  //production env
  installer_link_mac:
    "https://stg-ums.geocomply.net/installer/url?version=4.0.0.4&os=mac&id=D59IW53zNF",
  installer_link_win:
    "https://stg-ums.geocomply.net/installer/url?version=4.0.0.4&os=win&id=D59IW53zNF",
  Secret_Key:"5d31ef1d1bdc8a4a3d35acd6fcd6513a", //"28443cb6c4f474b44507c91cd08d80e2",
  env: "prod",
  decryption_key:"7A3D8A4FBB74C716A725A93E7FED783A", // "33C5728BA68F8EBE8B3E9FE364FCDE3E",
  decryption_IV_key: "1B48A9C2177A4F713AC474FD5AFE7616", //"EBED8189C58DC17E02F6C6D2D5E54C1C",
  oobeeUrl:'https://us3-stg-oobee-v2.geocomply.net/',
  oobee_serviceUrl:'https://us3-stg-es.geocomply.net/oobee.php',
  solus_cdnlink:'https://stg-cdn.geocomply.com/211/gc-html'
  
 
};
