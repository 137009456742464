import React from "react";
import "./../Login/login.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";

const SuccessPassword = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={2} justifyContent="center" rowSpacing={1} className="login_port">
        <Grid item md={5}>
          <div className="login_page form_logout reset_page">
            <img
              src={require("../../images/logo.png")}
              className="logo"
              alt="logo"
            />
            {/* <h3>Set new password</h3>
            <p>Password must be 8-20 Characters.</p> */}

            <div>
            <img
              src={require("../../images/Group.png")}
              className="logo"
              alt="logo"
            />
            </div>
            <h3>All done!</h3>
            <p>Your password has been reset.</p>
            <Button color="primary" variant="contained" type="submit" onClick={()=>{
                navigate('/login')
            }}>
            Login
            </Button>


          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SuccessPassword;
