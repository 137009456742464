import React from "react";
import {
  Grid,
  Typography,
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faq = () => {

  return (
    <>
      <div
        className="container bg-color-rg space-bottom content-only"
        style={{ marginTop: 70, padding: 20 }}
      >
        <h2>SpinSaga FAQ</h2>
        <Accordion style={{ marginBottom: "30px" }} defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="body1" fontWeight="bold">
            How does SpinSaga ensure a risk-free gaming experience?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            At SpinSaga, we prioritize a risk-free gaming environment. Your winning Saga Coins are always secure and reserved for redemption.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }} defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="body1" fontWeight="bold">
            How can I earn Saga Coins?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            You can earn Saga Coins through:
              <ul>
              <li>SignUp bonus</li>
              <li>Daily login bonus</li>
              <li>Referral bonus</li>
              <li>Coin purchase packages</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }} defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Typography variant="body1" fontWeight="bold">
            What happens to my winning Saga Coins?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
            Your winning Saga Coins are safely stored and can only be used for redemption, giving you peace of mind while gaming.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }} defaultExpanded="true">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How do I create an account?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Getting started is easy!
              <br /> Just click on the Sign Up button and follow the steps to
              create your account. You can register using your existing email
              ID, create a Username, set a Password and you’re done! Once
              registered, verify your email to activate your account and start
              playing for FREE. Click{" "}
              <a href="https://play.spinsagacasino.com/signup">here</a> to sign
              up now.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How do I update my details?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Once you have created your profile, you will only be able to
              update the email and the account password from the ‘My Account’
              section. To do so, you must have access to your registered email.
              A notification email will be sent to your inbox to confirm the
              change, and if you forget your new password, access to your email
              will be required to reset it. You can get the reset link on your
              email in case of ‘Forgot Password’.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How can I contact customer support?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Our Customer support is available 24/7 to assist you with any
              questions or concerns. Contact us via:
              <ul>
                <li>Email: support@spinsagacasino.com</li>
                <li>
                  Live Chat: Accessible directly on our website for instant
                  assistance.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7-content"
            id="panel7-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How can I reset my password?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              To reset your account password, you can go to the ‘My Account’
              section once you login. Enter the current password and the new
              password. For verification, an email will be sent to your
              registered email. Please make sure, you have access to the same
              email address.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8-content"
            id="panel8-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How do I close my account?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              If you wish to close your SpinSaga, you may do so by going to the
              Contact Us section and submitting a request to close your account.
              If the reason behind the closure of your account is related to
              concerns about possible responsible social gameplay issues you
              must indicate this in your request. Further details are provided
              in our Responsible Gaming section.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9-content"
            id="panel9-header"
          >
            <Typography variant="body1" fontWeight="bold">
              Is it safe to play at SpinSaga?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Yes, SpinSaga is committed to providing a safe and secure gaming
              environment. We use industry-standard 256-bit SSL encryption
              technology to protect your personal and financial information.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10-content"
            id="panel10-header"
          >
            <Typography variant="body1" fontWeight="bold">
              What are Gold Coins?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Gold Coins are our in-game currency. You can use them to play
              exciting games and enjoy the real casino experience without
              wagering real money.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11-content"
            id="panel11-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How do I get Gold Coins?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              We give free Gold Coins when you purchase Saga Coin packages. Keep
              an eye out for promotions and bonuses across our website,
              newsletters, and social media. Additionally, you may earn coins as
              part of our loyalty rewards.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12-content"
            id="panel12-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How do I use Gold Coins?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Spend your Gold Coins to play various social casino games and
              unlock exciting features. The more you play, the more perks and
              entertainment options you can access.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13-content"
            id="panel13-header"
          >
            <Typography variant="body1" fontWeight="bold">
              Can I win real money with Gold Coins?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Gold Coins are only for entertainment purposes and cannot be
              exchanged for cash prizes. However, they provide a thrilling
              casino experience to enjoy risk-free.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14-content"
            id="panel14-header"
          >
            <Typography variant="body1" fontWeight="bold">
              Can I play on my mobile device?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Yes, SpinSaga is fully optimized for mobile gaming. Simply access
              our platform via your browser or download the app for a seamless
              gaming experience.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15-content"
            id="panel15-header"
          >
            <Typography variant="body1" fontWeight="bold">
              Are there any age restrictions for playing on SpinSaga?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              You must be over 18 years of age or over the minimum legal age
              required as stipulated in the jurisdiction of your residence.
              Proof of age may be required during registration to ensure
              compliance with legal regulations.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel16-content"
            id="panel16-header"
          >
            <Typography variant="body1" fontWeight="bold">
              What payment methods do you accept?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              We support multiple secure payment methods, including:
              <ul>
                <li>Credit/Debit Cards: Visa, Mastercard</li>
                <li>E-Wallets: Skrill</li>
              </ul>
              All transactions are encrypted for your protection.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel17-content"
            id="panel17-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How long does it take for my deposit to process?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Deposit processing times vary:
              <ul>
                <li>Credit/Debit Cards: Instant</li>
                <li>E-Wallets: Instant</li>
              </ul>
              Check your account balance after depositing to confirm.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel18-content"
            id="panel18-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How can I redeem my prizes?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              To redeem your winnings, simply click on the ‘Redeem’ button and
              explore a range of exciting Gift Card options from luxury brands
              such as Disney Cruise Line, Prada, Louis Vuitton, and more. Ensure
              you meet all eligibility requirements before initiating the
              redemption process to avoid any delays and enjoy your rewards
              seamlessly. But there is no monetary value to these gift cards,
              for more information, you can read our Terms & Conditions.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel19-content"
            id="panel19-header"
          >
            <Typography variant="body1" fontWeight="bold">
              What types of games do you offer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              We offer a wide variety of games, including slots and table games.
              Our collection is constantly expanding with new and exciting
              titles.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel20-content"
            id="panel20-header"
          >
            <Typography variant="body1" fontWeight="bold">
              Can I play games for free?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Yes, you can. Using the Gold Coins which you get as a welcome
              bonus or through daily promotions, you can experience the thrill
              of spinning and winning without risking any real money.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel21-content"
            id="panel21-header"
          >
            <Typography variant="body1" fontWeight="bold">
              What devices are compatible with SpinSaga?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Our platform is compatible with:
              <ul>
                <li>Desktops/Laptops: Windows 10+, macOS 10.13+</li>
                <li>Smartphones: iOS 13+, Android 8+</li>
              </ul>
              For optimal performance, ensure your device meets these minimum
              technical requirements.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel22-content"
            id="panel22-header"
          >
            <Typography variant="body1" fontWeight="bold">
              How do I update my browser for optimal performance?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              To ensure the best gaming experience, please ensure your browser
              is up-to-date. Most modern browsers like Chrome, Firefox, and
              Safari have automatic updates enabled.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "30px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel23-content"
            id="panel23-header"
          >
            <Typography variant="body1" fontWeight="bold">
              I'm experiencing technical difficulties. What should I do?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              Check your internet connection. Update your browser or switch to a
              different one. Clear your cache and cookies. If issues persist,
              contact our Customer Support Team via live chat or email.{" "}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Faq;
