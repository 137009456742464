import React from "react";
import "./../Login/login.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { useDispatch } from "react-redux";
import { updateUser } from "../../Utility/API";
import { endPoints } from "../../constant/Environment";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { useSnackbarContext } from "../../component/SnackbarContext";

const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .max(20, "Password should be of maximum 20 characters length")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .required("Password is required"),

  confirmPassword: yup
    .string("Enter your password")
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();
  const { email, otp } = location.state || {};
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // You can handle the form submission here

      let payload = {
        login: email,
        // idUser: userData?.idUser,
        passwd: values.password,
        otp: otp,
         oldPasswd: "",
      };
      let url = `${endPoints.api.CHANGE_PASS}`;

      try {
        const response = await updateUser(url, payload);

        dispatch(setLoading(false));
        if (response.status === "success") {
          showSuccessSnackbar(response.message);
          navigate("/success");
        }else{
          showErrorSnackbar(response.message)
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.error(error);
      }
    },
  });

  return (
    <>
      <Grid container spacing={2} justifyContent="center" rowSpacing={1} className="login_port">
        <Grid item md={5}>
          <div className="login_page form_logout new_forget_page">
            <img
              src={require("../../images/logo.png")}
              className="logo"
              alt="logo"
            />
            <h3>Set new password</h3>
            <p>
              Password must be 8-20 Characters, and include at least one
              uppercase letter, one lowercase letter, one number, and one
              special character.
            </p>

            <form onSubmit={formik.handleSubmit}>
              <div className="forget_div">
                <Typography variant="subtitle1" align="left">
                  Password
                </Typography>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <Typography variant="subtitle1" align="left">
                  Confirm Password
                </Typography>
                <TextField
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Enter your confirm password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </div>

              <Button color="primary" variant="contained" type="submit">
                Reset Password
              </Button>
              <p className="sign_p login_p">
                <Link to="/login">
                  <GoArrowLeft />
                  Back to login
                </Link>
              </p>
            </form>




          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default NewPassword;
