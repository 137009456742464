import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { addBetData, addData, getAllData } from "../../Utility/API";
import { endPoints } from "../../constant/Environment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSnackbarContext } from "../../component/SnackbarContext";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import moment from "moment";
import { CoPresentOutlined } from "@mui/icons-material";
import { CalendarToday as CalendarIcon } from "@mui/icons-material";
import CustomDatePicker from "../Customdatepicker";
import { isMobile } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import { setLoginData } from "../../redux/Reducers/AuthReducer/authSplice";


const MyRedeemptions = () => {
  const theme = useTheme();
  const loginData = useSelector((state) => state.auth.IsLoginData);
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [gamelist, setGameList] = useState([]);
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const [redeem, setRedeem] = useState("");

  useEffect(() => {
    getRedeemption();
  }, []);

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSearch = () => {
    getRedeemption();
  };

  const getRedeemption = async () => {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.GIFT_TRANSACTION}`;
    dispatch(setLoading(true));
    let data = {
      fromdate: fromDate,
      todate: toDate,
      user_id: loginData?.idUser,
    };
    try {
      dispatch(setLoading(true));
      let response = await addData(url, data);
      if (response.data.status == "success") {
        setGameList(response?.data?.data ?? "");
      } else {
        console.log(response.data.message);
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const today = new Date();

  const disableToDate = (date) => {
    if (fromDate) {
      const fromDateObj = moment(fromDate).format("YYYY-MM-DD");
      const currentDateObj = moment(date).format("YYYY-MM-DD");
      return currentDateObj < fromDateObj;
    }
    return false;
  };

    const cancelRedemption = async (alldata) => {
      let url = `${endPoints.api.CANCEL_REDEEMPTION}`;
      const data = {
        id:alldata?.id,
        gift_id:alldata?.gift_id,
        user_id:loginData?.idUser,
        status:"3",
      }
      dispatch(setLoading(true));
      try{
          let response = await addData(url,data)
          if(response?.data?.status == "success"){
            dispatch(setLoading(false));
            showSuccessSnackbar(response?.data?.message);
            getRedeemption();
            GetUserById();
          }
          else{
            showErrorSnackbar(response?.data?.message);
            dispatch(setLoading(false));
          }
      }
      catch(error){
        console.log(error);
        showErrorSnackbar(
        "Issue connecting to the server (error code 15). Please contact support for further assistance."
        );
        dispatch(setLoading(false));
      }
      finally {
        dispatch(setLoading(false));
      }
    }

      const GetUserById = async () => {
        try {
          if (loginData?.idUser) {
            const url = `${endPoints.api.GET_BY_USERID}/${loginData?.idUser}`;
            const response = await getAllData(url);
            dispatch(setLoginData({ user: response?.data, isAuthenticated: true }));
          }
        } catch (error) {
          console.error(error);
        }
      };

  return (
    <section
      className="inner_space table_design"
      style={{ paddingLeft: isMobile ? "20px" : "" }}
    >
      <Grid container spacing={3}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ marginTop: "30px" }}
        >
          My Redemptions
        </Typography>

        <Grid
          item
          xs={12}
          sm={6}
          md={9}
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <div className="from_flex">
              <Typography variant="h4" component="h4">
                From:
              </Typography>
              <CustomDatePicker
                value={fromDate}
                onChange={(newDate) => setFromDate(newDate)}
                maxDate={toDate ? new Date(toDate) : today}
              />
            </div>
          </Grid>

          <Grid item>
            <div className="from_flex">
              <Typography variant="h4" component="h4">
                To:
              </Typography>
              <CustomDatePicker
                value={toDate}
                onChange={(newDate) => setToDate(newDate)}
                minDate={fromDate ? new Date(fromDate) : null}
                maxDate={today}
                shouldDisableDate={disableToDate}
              />
            </div>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="success"
              disabled={fromDate && toDate ? false : true}
              onClick={handleSearch}
              sx={{
                backgroundColor: theme.palette.custom.red_white,
                color: theme.palette.custom.white_red,
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          sx={{ maxHeight: 500 }}
          className="table_custom"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Date Requested</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {gamelist.length > 0 &&
                gamelist.map((row) => {
                  return (
                    <TableRow key={row?.id}>
                      <TableCell>
                        <div>
                          {moment
                            .parseZone(row?.created_on)
                            .format("MMM DD, YYYY")}
                        </div>
                        <div>
                          {moment
                            .parseZone(row?.created_on)
                            .format("HH:mm:ss a")}
                        </div>
                      </TableCell>
                      <TableCell>{row?.sagacoin_price}</TableCell>
                      <TableCell>{"Gift Card"}</TableCell>
                      <TableCell>
                        {row?.status == "1"
                          ? "Pending"
                          : row?.status == "2"
                          ? "Delivered"
                          : row?.status == "3"
                          ? "Canceled"
                          : ""}
                      </TableCell>
                      <TableCell>{row?.transaction_id}</TableCell>
                      <TableCell>
                      {row?.status == "1" ? 
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: theme.palette.custom.red_white,
                            color: theme.palette.custom.white_red,
                            textTransform:"capitalize"
                          }}
                          onClick={()=>{cancelRedemption(row)}}
                        >
                          Cancel
                        </Button>
                        :""}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {gamelist.length == 0 && (
          <Button
            sx={{
              backgroundColor: theme.palette.custom.gray_green,
              color: theme.palette.custom.white_green,
              marginTop: "10px",
              width: "100%",
              height: 60,
            }}
          >
            <Typography style={{ textTransform: "none" }}>
              No redemptions yet. Your records will show here after you redeem
              rewards.
            </Typography>
          </Button>
        )}
      </Grid>
    </section>
  );
};

export default MyRedeemptions;
