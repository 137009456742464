import React from "react";

const About = () => {
  return (
    <div>
      <div
        className="container bg-color-about space-bottom content-only"
        style={{
          bgcolor: "background.black1",
          marginTop: 85,
          padding: 20,
          paddingLeft: 35,
        }}
      >
        <div className="inner-smallspace-rg">
          <div className="row">
            <div className="col-md-12">
              <div className="content-page-design">
                <h2>About SpinSaga Social Casino</h2>
                <h3>
                  Where the thrill never stops, and every spin tells a story.
                </h3>
                <p>
                  At SpinSaga, we redefine online entertainment, by offering an
                  immersive world of exciting games, engaging features, and
                  rewarding experiences. Designed to ignite your sense of
                  adventure, our platform ensures that every play is filled with
                  fun, creativity, and boundless possibilities
                </p>
                <p>
                  SpinSaga is not just a gaming platform — it’s a social
                  experience where the excitement of each spin connects players
                  to a vibrant community of thrill-seekers.
                </p>

                <h3>Our Mission</h3>
                <p>
                  At SpinSaga, our mission is to deliver exceptional
                  entertainment while maintaining the highest standards of
                  security, fairness, and engagement. Whether you&#39;re a
                  seasoned player or just starting your journey, we are here to
                  create a space tailored to your needs.{" "}
                </p>

                <h3>We Believe In:</h3>
                <ul>
                  <li>
                    <p>
                      <strong>Engagement:</strong> Delivering a visually
                      stunning, seamless, and immersive gaming platform.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Integrity:</strong> Ensuring fair gameplay through
                      state-of-the-art randomization technologies.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Trust:</strong> Protecting your data with advanced
                      encryption and privacy measures.
                    </p>
                  </li>
                </ul>

                <p>
                  SpinSaga is a place where players of all skill levels feel
                  valued, connected, and empowered.
                </p>

                <h3>Why Choose SpinSaga Casino?</h3>
                <p>
                  <strong>Rewarding Experiences</strong>
                </p>
                <p>
                  From daily coin bonuses and loyalty rewards to exciting
                  challenges and surprise promotions, we ensure there are
                  countless ways to enhance your gaming adventure. Unlock
                  exclusive badges, earn Saga Coins, and climb leaderboards to
                  showcase your achievements.
                </p>
                <p>
                  <strong>Secure and Transparent Gameplay</strong>
                </p>
                <p>
                  Player safety and fairness are at the heart of SpinSaga. Our
                  platform uses advanced encryption technology, and all games
                  are rigorously tested to guarantee fair outcomes.
                </p>

                <p>
                  <strong>Exceptional Customer Support</strong>
                </p>
                <p>
                  Our dedicated support team is available 24/7 to assist you.
                  Whether you need help resolving an issue or discovering new
                  features, we’re just a click away.
                </p>

                <p>
                  <strong>A Vibrant Social Community</strong>
                </p>
                <p>
                  SpinSaga is more than a platform — it’s a thriving community
                  of players who share your passion for gaming. Engage with
                  friends, compete in leaderboards, and celebrate your
                  achievements together in a fun and supportive environment.
                </p>

                <h3>Our Vision</h3>
                <p>
                  We believe in a future where online social gaming brings
                  excitement, connection, and trust to players worldwide.
                  SpinSaga is committed to evolving, listening to our players,
                  and enhancing every element of your gaming experience.
                </p>

                <p>
                  <strong>Our Goal: </strong> To become the most beloved social
                  gaming destination — one spin at a time.
                </p>

                <h3>Join the SpinSaga Today!</h3>

                <p>
                  The adventure is just beginning at SpinSaga. Whether you're
                  here for the thrill of games, the joy of unlocking new badges,
                  or to connect with fellow players, we welcome you to embark on
                  a journey like no other.
                </p>

                <p>
                  Discover the excitement, earn rewards, and become part of a
                  community that celebrates the magic of social gaming.
                </p>

                <p>
                  <strong>
                    Thank you for choosing SpinSaga — where every spin is a
                    story waiting to be told!
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
