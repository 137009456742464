import React, { useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { endPoints } from "../../constant/Environment";
import { addData } from "../../Utility/API";
import Loader from "../../component/Loader";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { useSnackbarContext } from "../../component/SnackbarContext";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const Forget = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();
  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //  navigate("/otp", { state: { email: 'values.email' } });
      let url = `${endPoints.api.FORGOT_PASSWORD}`;
      let payload = {
        login: values.email,
      };
      dispatch(setLoading(true));

      await addData(url, payload)
        .then((response) => {
          dispatch(setLoading(false));
          if (response.data.status == "success") {
            navigate("/otp", { state: { email: values.email } });
            //  SuccesToast(response.data.message);
          } else {
             showErrorSnackbar(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(setLoading(false));
        });
    },
  });

  return (
    <>
      <Grid container spacing={2} justifyContent="center" rowSpacing={1} className="login_port">
        <Grid item md={5}>
          <div className="login_page form_logout">
            <img
              src={require("../../images/logo.png")}
              className="logo"
              alt="logo"
            />
            <h3>Forgot Password?</h3>
            <p>No worries! We'll send the reset instructions to your inbox.</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="forget_div">
                <Typography variant="subtitle1" align="left">
                  Email Address
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Enter your registered Email Address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>

              <Button color="primary" variant="contained" type="submit">
                Reset Password
              </Button>
              <p className="sign_p login_p">
                {" "}
                <Link to="/login">
                  <GoArrowLeft />
                  Back to login
                </Link>
              </p>

              <p className="sign_p">
                Not a member yet? <Link to="/signUp">Sign up now!</Link>
              </p>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Forget;
