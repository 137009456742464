import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  Button,
  Box,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { useSnackbarContext } from "../../component/SnackbarContext";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { endPoints } from "../../constant/Environment";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { updateUser } from "../../Utility/API";
import NewPassword from "../NewPassword";
import SweepStakeTypography from "../../component/SweepStakeTypography";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import { setLoginData } from "../../redux/Reducers/AuthReducer/authSplice";
import { getAllData } from "../../Utility/API";
import { useFormik } from "formik";
import ProfileForm from "./ProfilePage";

const validationSchema = Yup.object({
  updatePassword: Yup.string("Enter your current password").required(
    "New Password is required"
  ),

  newPassword: Yup.string("Enter your new password")
    .min(8, "Password should be of minimum 8 characters length")
    .max(20, "Password should be of maximum 20 characters length")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .oneOf([Yup.ref("updatePassword"), null], "Passwords do not match")
    .required("Confirm Password is required"),
});

const Profile = () => {
  const theme = useTheme();
  const authState = useSelector((state) => state.auth);
  const { IsLoginData } = authState;

  const dispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const navigate = useNavigate();
  const validationSchemaMail = Yup.object({
    newEmail: Yup.string()
      .email("Invalid email address")
      .required("Email is Required"),
    confirmEmail: Yup.string()
      .email("Invalid email address")
      .oneOf([Yup.ref("newEmail"), null], "Email addresses do not match")
      .required("confirm email is Required"),
  });

  const handleSubmitEmail = async (values, { resetForm }) => {
    const data = {
      login: IsLoginData?.login,
      idUser: IsLoginData?.idUser,
      email: values.newEmail,
    };
    try {
      const url = `${endPoints.api.UPDATE_EMAIL}`;
      dispatch(setLoading(true));
      const response = await updateUser(url, data);
      dispatch(setLoading(false));
      if (response?.status === "success") {
        resetForm();
        GetUserById();
        showSuccessSnackbar(response?.message);
      } else {
        showErrorSnackbar(response?.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      showErrorSnackbar(
        "Issue connecting to the server (error code 15). Please contact support for further assistance."
      );
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      updatePassword: "",
      newPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let payload = {
        login: IsLoginData?.login,
        idUser: IsLoginData?.idUser,
        oldPasswd: values.updatePassword,
        passwd: values.newPassword,
      };
      dispatch(setLoading(true));
      let url = `${endPoints.api.CHANGE_PASS}`;

      try {
        const response = await updateUser(url, payload);

        dispatch(setLoading(false));
        if (response.status === "success") {
          showSuccessSnackbar(response.message);
          resetForm();
        } else {
          showErrorSnackbar(response.message);
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.error(error);
      }
    },
  });

  const GetUserById = async () => {
    try {
      if (IsLoginData?.idUser) {
        const url = `${endPoints.api.GET_BY_USERID}/${IsLoginData?.idUser}`;
        const response = await getAllData(url);
        dispatch(setLoginData({ user: response.data, isAuthenticated: true }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetUserById();
  }, []);

  const UpdateUserDetail = async () => {
    let data = {
      idUser: IsLoginData?.idUser,
      login: IsLoginData?.login,
      name: IsLoginData?.name,
      surname: IsLoginData?.surname,
      email: IsLoginData?.email,
      birthDate: IsLoginData?.birthDate,
      nation: IsLoginData?.nation,
      country: IsLoginData?.country,
      town: IsLoginData?.town,
      address: IsLoginData?.address,
      mobile: IsLoginData?.mobile,
      zipCode: IsLoginData?.zipCode,
    };

    let url = `${endPoints.api.UPDATE_PERSONAL}`;
    dispatch(setLoading(true));

    try {
      const response = await updateUser(url, data);
      dispatch(setLoading(false));
      if (response?.status == "success") {
        showSuccessSnackbar(response?.message);
        GetUserById();
      } else if (response?.status == "kycfailed") {
        navigate("/kyc-check");
      } else {
        showErrorSnackbar(response?.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
      showErrorSnackbar("An error occurred while updating your information.");
    }
  };

  return (
    <div className="form_profile" style={{ padding: 24 }}>
      <Box display="flex" justifyContent="space-between">
        <Grid>
          <Typography
            className="title_heading"
            variant="h4"
            component="h2"
            gutterBottom
          >
            Personal Details
          </Typography>
        </Grid>
        <Grid>
          {IsLoginData?.validation != "1" ? (
            <Button
              sx={{
                backgroundColor: theme.palette.custom.greenbtn,
                color: theme.palette.custom.white,
                borderRadius: "24px",
                textTransform: "capitalize",
              }}
              variant="contained"
              onClick={() => {
                UpdateUserDetail();
              }}
            >
              Verify Profile
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Box>
      <ProfileForm  GetUserById={GetUserById} />
      {/* <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>

        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            First name
          </Typography>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            value={IsLoginData?.name}
            placeholder="Enter your First Name"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            Last name
          </Typography>
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            placeholder="Enter your Last Name"
            value={IsLoginData?.surname}
            type="text"
            disabled
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            Email
          </Typography>
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            placeholder="Enter your Last Name"
            value={IsLoginData?.email}
            type="text"
            disabled
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            Date of Birth
          </Typography>
          <TextField
            fullWidth
            id="dob"
            name="dob"
            value={IsLoginData?.birthDate}
            placeholder="Enter your Date of Birth"
            type="text"
            disabled
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            Address
          </Typography>
          <TextField
            fullWidth
            id="address"
            name="address"
            placeholder="Enter your Address"
            value={IsLoginData?.address}
            type="text"
            disabled
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            City
          </Typography>
          <TextField
            fullWidth
            id="city"
            name="city"
            placeholder="Enter your City"
            value={IsLoginData?.town}
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            State
          </Typography>
          <TextField
            fullWidth
            id="state"
            name="state"
            value={IsLoginData?.stateName}
            placeholder="Enter your State"
            type="text"
            disabled
          />
        </Grid>


        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            Zip code
          </Typography>
          <TextField
            fullWidth
            id="zipCode"
            name="zipCode"
            value={IsLoginData?.zipCode}
            placeholder="Enter your Zip Code"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography variant="subtitle1" align="left">
            Country
          </Typography>
          <TextField
            fullWidth
            id="country"
            name="country"
            value={IsLoginData?.country}
            placeholder="Enter your Country"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={5}>
          {IsLoginData?.validation != "1" && (
            <>
              <Button
                sx={{
                  backgroundColor: theme.palette.custom.greenbtn,
                  color: theme.palette.custom.white,

                  textTransform: "capitalize",
                  marginTop: "50px",
                  width: "50%",
                }}
                variant="contained"
                onClick={() => {}}
              >
                Update Profile
              </Button>
            </>
          )}
        </Grid>
      </Grid> */}
      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={5}>
          <Card
            className="profile_card"
            style={{ marginBottom: 24, marginTop: 30 }}
            sx={{
              backgroundColor: theme.palette.custom.blue_bg,
            }}
          >
            <CardContent sx={6}>
              <Typography variant="h4" align="left" gutterBottom>
                Update Email
              </Typography>
              <Typography variant="subtitle2" className="form_p">
                You will need to validate your new email address before any
                changes will be made to your account. You will receive a
                notification email to your original address advising of the
                change.
              </Typography>

              <Formik
                initialValues={{ newEmail: "", confirmEmail: "" }}
                validationSchema={validationSchemaMail}
                onSubmit={handleSubmitEmail}
                setFieldValue
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <Grid>
                      <div className="forget_div">
                        <Typography variant="subtitle1" align="left">
                          New Email Address:
                        </Typography>
                        <Field
                          as={TextField}
                          fullWidth
                          id="newEmail"
                          name="newEmail"
                          placeholder="Enter your new email"
                          type="email"
                          variant="outlined"
                          error={touched.newEmail && Boolean(errors.newEmail)}
                          helperText={touched.newEmail && errors.newEmail}
                        />

                        <Typography variant="subtitle1" align="left">
                          Confirm Email Address:
                        </Typography>
                        <Field
                          as={TextField}
                          fullWidth
                          id="confirmEmail"
                          name="confirmEmail"
                          placeholder="Confirm your email"
                          type="email"
                          variant="outlined"
                          error={
                            touched.confirmEmail && Boolean(errors.confirmEmail)
                          }
                          helperText={
                            touched.confirmEmail && errors.confirmEmail
                          }
                        />
                      </div>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          sx={{
                            backgroundColor: theme.palette.custom.greenbtn,
                            color: theme.palette.custom.white,
                          }}
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>

        {/* Update Password Card */}
        <Grid item xs={12} md={5}>
          <Card
            className="profile_card"
            style={{ marginBottom: 24, marginTop: 30 }}
            sx={{
              backgroundColor: theme.palette.custom.blue_bg,
            }}
          >
            <CardContent>
              <Typography variant="h4" align="left" gutterBottom>
                Update Password
              </Typography>
              <Typography className="form_p" variant="subtitle2">
                Before updating your password, please confirm that you have
                access to your account email address. If you forget your new
                password you will need access to your email to reset your
                password.{" "}
              </Typography>
              {/* <Formik
            initialValues={{ updatePassword: "", newPassword: "" }}
            validationSchema={validationSchemaPassword}
            onSubmit={handleSubmitPassword}
            setFieldValue
          >
            {({ errors, touched, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={3} xs={6}>

                    <Grid item xs={10}>
                      <Typography variant="subtitle1" align="left">
                        Update Password
                      </Typography>
                      <Field
                        as={TextField}
                        fullWidth
                        id="updatePassword"
                        name="updatePassword"
                        placeholder="Enter your current password"
                        type="text"
                        error={touched.updatePassword && Boolean(errors.updatePassword)}
                        helperText={touched.updatePassword && errors.updatePassword}
                      />
                    </Grid>


                    <Grid item xs={10}>
                      <Typography variant="subtitle1" align="left">
                        New Password
                      </Typography>
                      <Field
                        as={TextField}
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        placeholder="Enter your new password"
                        type="text"
                        error={touched.newPassword && Boolean(errors.newPassword)}
                        helperText={touched.newPassword && errors.newPassword}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Button
                        type="submit"
                        sx={{
                          background: theme.palette.custom.greenbtn,
                          color: theme.palette.custom.white,
                        }}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik> */}

              <form onSubmit={formik.handleSubmit}>
                <div className="forget_div">
                  <Typography variant="subtitle1" align="left">
                    New Password
                  </Typography>
                  <TextField
                    fullWidth
                    id="updatePassword"
                    name="updatePassword"
                    placeholder="Enter your new password"
                    value={formik.values.updatePassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.updatePassword &&
                      Boolean(formik.errors.updatePassword)
                    }
                    helperText={
                      formik.touched.updatePassword &&
                      formik.errors.updatePassword
                    }
                  />
                  <Typography variant="subtitle1" align="left">
                    Confirm Password
                  </Typography>
                  <TextField
                    fullWidth
                    id="newPassword"
                    name="newPassword"
                    placeholder="confirm your new password"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                  />
                </div>

                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    background: theme.palette.custom.greenbtn,
                    color: theme.palette.custom.white,
                  }}
                >
                  Update
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid className="buy_footer" container spacing={5}>
        <Grid item container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              size="small"
              sx={{
                background: theme.palette.custom.greenbtn,
                color: theme.palette.custom.white,
              }}
              onClick={() => {
                navigate("/purchase-history");
              }}
            >
              <span style={{ textTransform: "capitalize" }}>
                Purchase History
              </span>
            </Button>
          </Grid>

          <Grid item>
            <Button
              size="small"
              sx={{
                background: theme.palette.custom.blue,
                color: theme.palette.custom.white,
                textAlign: "center",
              }}
              onClick={() => {
                navigate("/game-history");
              }}
            >
              <span style={{ textTransform: "capitalize" }}>Game History</span>
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SweepStakeTypography></SweepStakeTypography>
        </Grid>
      </Grid>

      {/* <Grid container alignItems="center">
        <FiberManualRecordIcon
          fontSize="5px"
          style={{ marginRight: 8, width: "10px" }}
        />
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            navigate("/game-history");
          }}
          className="f18_700"
        >
          Gaming History
        </Typography>
      </Grid> */}

      <Grid container alignItems="center " sx={{ marginTop: 4 }}>
        <SweepStakeTypography />
      </Grid>
    </div>
  );
};

export default Profile;
