import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Button,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IoInformationCircle } from "react-icons/io5";
import logo from "../../images/logo.png";
import img2 from "../../images/gold-coin.svg";
import img3 from "../../images/sega-coin.svg";
import "./infoStyle.css";
function InfoModal() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleClickOpen} style={{ marginTop: "5px" }}>
        <IoInformationCircle size={20} />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        id="infoModal"
        className="info-modal"
        BackdropProps={{
          style: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        }}
      >
        <DialogTitle sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}

        >
          <img src={img3} alt="Sweepstakes Coins" style={{ height: 20 }} />
          <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
            What are Sweepstakes Coins?
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>

        </DialogTitle>
        <hr />
        <DialogContent
          sx={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          <Grid container spacing={2}>
            {/* Gold Coins Section */}
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <img
                  src={img2}
                  alt="Gold Coins"
                  style={{ height: 20, marginRight: 10 }}
                />
                <Typography variant="h6">Gold Coins</Typography>
              </div>
              <Typography style={{ paddingLeft: "8px" }}

              >
                {/* component="ul" */}
                <li>Play for Free: No monetary value.</li>
                <li>Daily rewards: Collect Gold Coins every day.</li>
                <li>Gold Coins cannot be redeemed for real cash prizes.</li>
              </Typography>
            </Grid>

            {/* Sweepstakes Coins Section */}
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <img
                  src={img3}
                  alt="Sweepstakes Coins"
                  style={{ height: 20, marginRight: 10 }}
                />
                <Typography variant="h6">Sweepstakes Coins</Typography>
              </div>
              <Typography style={{ paddingLeft: "8px" }}>
                <li>Always free: No purchase necessary.</li>
                <li>Redeemable for real prizes.</li>
                <li>1 SC redeemable for $1 USD.</li>
              </Typography>
            </Grid>

            {/* How to Get Free Sweepstakes Coins Section */}
            <Grid item xs={12}>
              <Typography variant="h1" component="div"
                className="box_gray"
                sx={{
                  backgroundColor: theme.palette.custom.lgray,

                }}
              >
                <Typography variant="h6">
                  How do I get free&nbsp;
                  <Typography
                    sx={{

                      color: theme.palette.custom.green_green,
                    }}
                    variant="p" component="span">
                    Sweepstakes Coins
                  </Typography>

                  ?
                </Typography>
                <Typography style={{ paddingLeft: "8px" }}>
                  <li>Login Daily</li>

                  <li>Refer a Friend</li>
                  {/* <li>Social Competitions</li>
                <li>Mail-in Request</li> */}

                  <li>Buy Gold Coins*</li>
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    display: "block",
                    mt: 1,
                    color: theme.palette.text.secondary,
                  }}
                  className="privacy_text"
                >
                  *Promotional Free Sweepstakes Coins only available with selected
                  Gold Coin Packages. See full{" "}
                  <a
                    href="/sweepstakes"
                    style={{ color: theme.palette.text.secondary }}
                  >
                    Sweepstakes Rules here.
                  </a>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default InfoModal;
