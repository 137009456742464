import React, { useState, useEffect } from "react";
import "./signup.css";

import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import MultiStepForm from "../MultiStepForm";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { endPoints } from "../../constant/Environment";
import { addData } from "../../Utility/API";
import { geoClientMob } from "../../Utility/ConfigData";
import { useAuth } from "../../AuthContext";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setLicense,
} from "../../redux/Reducers/GlobalReducer/globalSlice";
import { useSnackbarContext } from "../../component/SnackbarContext";
import { useSelector } from "react-redux";
import { setLoginData } from "../../redux/Reducers/AuthReducer/authSplice";
import { decryptUsingAES128 } from "../../Utility/ConfigData";
import { useNavigate } from "react-router-dom";
import { devicetype } from "../../Utility/functions/Helper";
import { BrowserInfo } from "../../Utility/functions/Helper";
import { getCookie } from "../../Utility/functions/Helper";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

var geoClient = null;

const SignUp = () => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarContext();
  const { login } = useAuth();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let deviceId = "web";
  let operatingPlatform = navigator.platform;
  const navigate = useNavigate();
  const [googleSignUpData, setGoogleSignUpData] = useState(null);
  const location = useLocation();

  const Googlevalues = location.state;

  useEffect(() => {
    if (Googlevalues) {
      setGoogleSignUpData(Googlevalues);
    }
  }, []);

  function getGeolocation() {
    let url = `${endPoints.apiBaseUrl}${endPoints.api.geocomplyLience1}`;
    axios
      .get(url)
      .then((response) => {
        let result = response?.data?.data?.split('">')[1];
        result = result?.split("</")[0];
        dispatch(setLicense(result));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getGeolocation();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const googleSignUp = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        if (tokenResponse.access_token) {
          const userInfo = await axios.get(
            "https://www.googleapis.com/oauth2/v3/userinfo",
            {
              headers: {
                Authorization: `Bearer ${tokenResponse.access_token}`,
              },
            }
          );
          const resultData = userInfo.data;
          let url = `${endPoints.api.GOOGLE_LOGIN}`;
          let data = {
            firstname: resultData?.given_name,
            lastname: resultData?.family_name,
            email: resultData?.email,
            picture: resultData?.picture,
            username: "",
            birthDate: "",
            promoCode: "",
            google_code: resultData?.sub,
            platform: operatingPlatform,
            browser: BrowserInfo(),
            device: devicetype(),
            referral: getCookie("ref"),
            campaign: getCookie("campaign"),
          };
          await addData(url, data)
            .then((response) => {
              dispatch(setLoading(false));
              if (response.data.status === "success") {
                if (response.data.data.userStatus == "1") {
                  setGoogleSignUpData(resultData);
                }
                // let maintenanceData = state?.auth.maintenanceData;    have to implement maintainance functionality in future
                // if (
                //   maintenanceData?.specifiers?.maintenance_mode &&
                //   !maintenanceData?.whiteList.includes(response.data.data.idUser)
                // ) {
                //   navigate("/under-maintenance");
                //   return;
                // }
                else {
                  dispatch(
                    setLoginData({
                      user: response.data.data,
                      isAuthenticated: true,
                    })
                  );
                  showSuccessSnackbar("User Loggedin sucessfully");
                  login();

                  geoClient = geoClientMob.createClient();

                  // Set up parameters
                  geoClient.setUserId(response.data.data.idUser);
                  geoClient.setReason("LOGIN");
                  geoClient.setLicense(state?.global?.license);

                  geoClient.events
                    .on("hint", function (BROWSER_GEOLOCATION_DENIED, data) {
                      //e.g. show hint messages to users
                    })
                    .on("engine.success", function (text, xml) {
                      let payload = {
                        deviceId: deviceId,
                        userId: response.data.data.idUser,
                        userName: `${response.data.data.name} ${response.data.data.surname}`,
                      };

                      let decryptResponse = decryptUsingAES128(text, payload);

                      console.log("response", decryptResponse);
                    })
                    .on("*.failed", function (code, message) {
                      if (
                        this.event === "revise.failed" ||
                        this.event === "config.failed" ||
                        this.event === "engine.failed"
                      ) {
                        if (
                          code ===
                          geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION
                        ) {
                          //network issue, we can add retry logic if needed
                          // alert(message);
                          showErrorSnackbar(message);
                        } else if (
                          code ===
                            geoClient.ErrorCodes
                              .CLNT_ERROR_LICENSE_INVALID_FORMAT ||
                          code ===
                            geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED
                        ) {
                          // alert(message);
                          // showErrorSnackbar(message);
                          //e.g. update license and retry geolocating
                        } else if (
                          code ===
                          geoClient.ErrorCodes
                            .CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS
                        ) {
                          showErrorSnackbar(message);
                          //it's safe to be ignored, or we can wait for previous request to complete and try again
                        } else {
                          //something went wrong with GeoComply service, your license or your custom data
                          //e.g. submit log to record the issue
                          showErrorSnackbar(message);
                        }
                      } else if (this.event === "browser.failed") {
                        // alert(message);
                        // showErrorSnackbar(message);
                        //it's safe to be ignored, you can remove this block or add your own logic
                      }
                    });
                  // Request geolocation
                  geoClient.request();

                  navigate("/");
                }
              } else {
                showErrorSnackbar(response.data.message);
              }
            })
            .catch((error) => {
              console.log("error", error);
              dispatch(setLoading(false));
              showErrorSnackbar(
                "Issue connecting to server (error code 15). Please contact support for further assistance."
              );
            });

          // setGoogleSignUpData(resultData); // Store Google user data
          // console.log("data",resultData);
        }
      } catch (error) {
        console.error("Google login failed:", error);
      }
    },
    onError: () => {
      console.error("Google login failed");
    },
  });

  return (
    <>
      <Grid container spacing={2} justifyContent="center" rowSpacing={1} className="login_port">
        <Grid item md={5} style={{marginTop:isMobile?"40px":"90px"}}>
          <div className="signup_page form_logout">
            <img
              src={require("../../images/logo.png")}
              className="logo"
              alt="logo"
            />
            <h3>Register</h3>
            <p>Grab your Free Coins, Start Winning Today!</p>
            <MultiStepForm
              googleSignUp={googleSignUp}
              googleSignUpData={googleSignUpData}
            />
            <p className="sign_p">
              Already have an account? <Link to="/login">Log in</Link>
            </p>
            {/* <form onSubmit={formik.handleSubmit}>
              <Typography variant="subtitle1" align="left">
                Email Address
              </Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                placeholder="Enter your Email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <div className="pass_div">
                <Typography variant="subtitle1" align="left">
                  Password
                </Typography>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <FaEyeSlash />
              </div>

              <Button color="primary" variant="contained" type="submit">
                Continue
              </Button>

              <p className="sign_p">
                Already have an account? <Link to="/login">Log in</Link>
              </p>
            </form> */}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUp;
