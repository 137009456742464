export const SkrillEnv = {
    //staging env
    // keys:"T1QtMTEwMTk0MDpCLXFhMi0wLTY2ZGYyMGFhLTAtMzAyYzAyMTQ3ZDUxYmUyY2VmZDBkZTE4NTZjNTUwZGI1ZGJmNTc2MTY3MzBiMDJiMDIxNDE2OTY1ZTUwMzYyNmUyZDQ5MTYzMjI5OTlhYzk4OTE0NGM2MzYyNDU=",
    // keys:"T1QtMTA4OTc0MDpCLXFhMi0wLTY1ZWY1NGQ2LTAtMzAyYzAyMTQyZDlkMjY1NDViZDhkOWI1ODA1OGRjMmU4YzZkMmRhOGY3ODc1NThhMDIxNDE2ZWMyZDIzMWFiODZiMTJkOTExOWViNTFhZGYxY2Q3OTVhMjY0NTY=",   //stage keys old
    // Env:'TEST', //stage or dev

    //Prod Keys
    keys : "T1QtMzQxMTYyOkItcDEtMC02NzRkZmY2MC0wLTMwMmMwMjE0MDFjZDI5OWQzODhkNWEyMjU3NDcyMzcyMWEwOGVkODk5YTJkZWRiODAyMTQ3ZjBmZTkzYzNmZDQ1MDg2M2M4M2YxZTYwYzJhMGYzMDRjODlmMGFj",    //Prod kyes
    Env:'LIVE'
  }
