import { Redeem } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import gift from "../../images/main3.png";
import sagacoin from "../../images/Sagacoin.png";
import { useTheme } from "@emotion/react";
import { endPoints } from "../../constant/Environment";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { addData, getAllData } from "../../Utility/API";
import { isMobile } from "react-device-detect";
import byuborder from "../../images/border.png";
import { setLoginData } from "../../redux/Reducers/AuthReducer/authSplice";
import { useSnackbarContext } from "../../component/SnackbarContext";

const GiftCard = () => {
  const loginData = useSelector((state) => state.auth.IsLoginData);
  const dispatch = useDispatch();
  const [giftList, setGiftList] = useState([]);
  const [open, setOpen] = useState(false);
  const [alldata, setAlldata] = useState([]);
  const theme = useTheme();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  const [openConfirm, setOpenConfirm] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "100%" : 500, // Responsive width
    bgcolor: theme.palette.custom.white,
    border: "2px solid #000",
    borderRadius: "5px",
    boxShadow: 24,
    p: 1,
  };

  const handleOpen = (data) => {
    setOpen(true);
    setAlldata(data);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getExchangeList();
  }, []);

  const getExchangeList = async (list) => {
    let url = `${endPoints.api.GET_EXCHANGE}/${loginData?.idUser}`;
    dispatch(setLoading(true));

    await getAllData(url)
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.status === "success") {
          if (response?.data?.length > 0) {
            setGiftList(response?.data);
          }
        } else {
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.error(error);
      });
  };

  const RedeemGift = async (giftvalues) => {
    dispatch(setLoading(true));
    setOpen(false);
    try {
      const data = {
        gift_id: giftvalues.id,
        user_id: loginData?.idUser || null,
        gift_title: giftvalues.gift_title,
        sagacoin_price: giftvalues.sagacoin_price,
        startdate: giftvalues.startdate,
        starttime: giftvalues.starttime,
        enddate: giftvalues.enddate,
        endtime: giftvalues.endtime,
        status: giftvalues.isactive,
        id: giftvalues.id,
      };
      const url = `${endPoints.api.BUY_GIFT}`;
      const response = await addData(url, data);
      if (response?.data?.status == "success") {
        console.log("srar", response);
        dispatch(setLoading(false));
        // showSuccessSnackbar(response?.data?.message);
        setOpenConfirm(true);
        setOpen(false);
        GetUserById();
      } else {
        showErrorSnackbar(response?.data?.message);
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
      showErrorSnackbar(
        "An error occurred while processing your request. Please try again later."
      );
    }
  };

  const GetUserById = async () => {
    try {
      if (loginData?.idUser) {
        const url = `${endPoints.api.GET_BY_USERID}/${loginData?.idUser}`;
        const response = await getAllData(url);
        dispatch(setLoginData({ user: response?.data, isAuthenticated: true }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className="inner_space table_design">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="modal_purchase"
      >
        <Box sx={style}>
          <Typography
            sx={{ color: theme.palette.custom.black_black }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            Please confirm your purchase
          </Typography>
          <Box
            display={isMobile ? "" : "flex"}
            justifyContent={isMobile ? "" : "space-evenly"}
          >
            <Grid md="6" xs="12" sm="12" sx={{ padding: 2 }}>
              <img
                style={{
                  height: isMobile ? 95 : 134,
                  width: isMobile ? "100%" : 212,
                }}
                src={`${endPoints.imageCDN}${alldata.creative}`}
              />
            </Grid>
            <Grid md="6" xs="12" sm="12" sx={{ padding: 2 }}>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.custom.black_light }}
                fontWeight="bold"
                textAlign="center"
              >
                {alldata?.gift_title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.custom.black_light }}
                textAlign="center"
                fontWeight="bold"
              >
                ${alldata?.sagacoin_price} {""}Value
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ marginTop: "20px" }}
              >
                <img
                  style={{ width: 24, marginRight: "8px", marginTop: "5px" }}
                  src={sagacoin}
                  alt="SagaCoin"
                />
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.custom.black_light }}
                  fontWeight="bold"
                >
                  {alldata?.sagacoin_price}
                </Typography>
              </Box>
            </Grid>
          </Box>
          <Grid>
            <img src={byuborder} />
          </Grid>
          <Typography id="modal-modal-description">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="large"
                sx={{
                  background: theme.palette.custom.red_gradient,
                  color: theme.palette.custom.white,
                  marginTop: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  RedeemGift(alldata);
                }}
              >
                Redeem Now
              </Button>
            </Box>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                color: theme.palette.custom.black_light,
                marginTop: "10px",
              }}
            >
              {alldata?.terms_condition}
            </Typography>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openConfirm}
        // onClose={()=>setOpenConfirm(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ color: theme.palette.custom.black_light }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            Gift Card Purchase Request Processed
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              color: theme.palette.custom.black_light,
              marginTop: "10px",
              fontSize: "18px",
            }}
          >
            Your gift card purchase request has been successfully processed. You
            will receive the gift card code within 24-48 hours. Thank you!
          </Typography>
          <Typography
            id="modal-modal-description"
            display="flex"
            justifyContent="space-evenly"
          >
            <Box>
              <Button
                size="large"
                sx={{
                  background: theme.palette.custom.red_gradient,
                  color: theme.palette.custom.white,
                  marginTop: "20px",
                  textTransform: "capitalize",
                }}
                onClick={() => setOpenConfirm(false)}
              >
                Close
              </Button>
            </Box>
          </Typography>
        </Box>
      </Modal>

      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h4" component="h2" gutterBottom>
            SpinSaga Exchange
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: theme.palette.custom.golden_yellow }}
          >
            You can spend your SAGA Coins on any of the items in this store. All
            items include shipping or digital delivery. Looking to save up for
            an item, simply favorite is and we will alert you when you have
            enough chips!
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              <Typography sx={{ color: theme.palette.custom.golden_yellow }}>
                SC Balance :
              </Typography>
              <img
                src={sagacoin}
                alt="sagacoin"
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              />{" "}
              <Typography
                sx={{ marginRight: "4px", color: theme.palette.custom.white }}
              >
                {loginData?.account?.sc_main}
                {console.log(
                  "loginData?.account?.sc_main",
                  loginData?.account?.sc_main
                )}
              </Typography>
            </Box>
          </Typography>
        </Grid>
        {giftList.length > 0 &&
          giftList.map((item) => {
            return (
              <Grid item md="3" xs="6" key={item?.id}>
                <Card
                  sx={{
                    maxWidth: 280,
                    padding: "5px",
                    height: isMobile ? 260 : 292,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ padding: "10px" }}>
                    <CardMedia
                      sx={{
                        height: isMobile ? 95 : 134,
                      }}
                      image={`${endPoints.imageCDN}${item.creative}`}
                      title={gift?.title}
                    />
                  </Box>
                  <CardContent sx={{ padding: "0px" }}>
                    <Typography
                      gutterBottom
                      variant="body2"
                      sx={{ color: theme.palette.custom.white }}
                      textAlign="center"
                    >
                      {item?.gift_title?.length > 45
                        ? `${item.gift_title.substring(0, 42)}...`
                        : item.gift_title}
                    </Typography>
                    <Typography
                      variant="body2"
                      textAlign="center"
                      sx={{ color: theme.palette.custom.white }}
                    >
                      ${item?.sagacoin_price} Value
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <img
                        src={sagacoin}
                        alt="coins"
                        style={{ width: 20, height: 20 }}
                      />
                      <Typography variant="body2" sx={{ marginLeft: "3px" }}>
                        {item?.sagacoin_price}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        background: theme.palette.custom.redblue_border,
                        color: theme.palette.custom.white,
                        display: "flex",
                        justifyContent: "end",
                      }}
                      size="small"
                      disabled={
                        loginData?.account.sc_main < 25 ||
                        item?.sagacoin_price > loginData?.account.sc_main
                      }
                      onClick={() => {
                        handleOpen(item);
                      }}
                    >
                      Redeem
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      
      {/* {parseInt(loginData?.account?.sc_main)  < 99 && (
        <div className="highlighted-header" style={{ marginTop: "20px" }}>
          <strong>
            To view redeem offers, you need a minimum of 100 Redeemable Saga
            Coins.
          </strong>
        </div>
      )} */}

    </section>
  );
};

export default GiftCard;
