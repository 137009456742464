import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Button,
  ListItemAvatar,
  Avatar,
  MenuList,
  Box,
  Menu,
  Grid,
  Divider,
} from "@mui/material";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom"; // Import Outlet for nested routing
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/logo.png";

import coin from "../../images/gold-coin.png";
import { setThemeMode } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { FaCoins, FaPlus, FaRegUser } from "react-icons/fa";
import { useAuth } from "../../AuthContext";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FaUser } from "react-icons/fa";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";
import {
  setIsDrawerOpen,
  setShowSearchList,
} from "../../redux/Reducers/sidebarReducer/sidebarSlice";
import { isMobile } from "react-device-detect";
import { sumSC_credit_SC_Main } from "../../Utility/functions/Helper";
import { endPoints } from "../../constant/Environment";

import { getAllData } from "../../Utility/API";
import SearchableCardList from "../../screens/SearchableCardList";
import { LinearProgress } from "@mui/material";
import { FaSignOutAlt } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";

const NavBar = () => {
  const navigate = useNavigate(); // Initialize navigate
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const location = useLocation();
  const globalState = useSelector((state) => state.global);
  const authState = useSelector((state) => state.auth);
  const { IsLoginData,isAuthenticated } = authState;
  const [search, setSearch] = useState("");
  const [displaySearchUI, setDisplaySearchUI] = useState(false);
  const [searchGameList, setSearchGameList] = useState(null);
  const sideBarState = useSelector((state) => state.sidebar);
  const { menuList, isDrawerOpen, showSearchList } = sideBarState;
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);

  const footerRoutes = [
    "/contact-us",
    "/about-us",
    "/responsible-gaming",
    "/faq",
    "/terms-conditions",
    "/privacy-policy",
    "/sweepstakes",
  ];

  const isFooterRoute = footerRoutes.includes(location.pathname);


  const handleProfileClick = (event) => {
    if (profileMenuAnchor) {
      handleClose(); // Close if already open
    } else {
      setProfileMenuAnchor(event.currentTarget); // Open the menu
    }
  };

  const handleClose = () => {
    setProfileMenuAnchor(null);
  };

  const handleNavigation = (url) => {
    navigate(url);
    handleClose();
  };

  useEffect(() => {
    if (isMobile) {
      dispatch(setIsDrawerOpen(false));
    } else {
      dispatch(setIsDrawerOpen(true));
    }
  }, []);

  const handleDrawerToggle = () => {
    dispatch(setIsDrawerOpen(!isDrawerOpen));
  };

  const logoutClick = () => {
    logout();
    dispatch(setThemeMode("dark"));
    navigate("/login");
  };

  const [value, setValue] = useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const theme = useTheme();

  const searchGame = (e) => {
    setSearch(e.target.value);
    getGameByName(e.target.value);
  };

  const getGameByName = (name) => {
    if (name) {
      let url = `${endPoints.api.SERACH_GAME}/${name}`;
      getAllData(url).then((response) => {
        if (response.status == "success" && response.data) {
          dispatch(setShowSearchList(true));
          setDisplaySearchUI(true);
          setSearchGameList(response.data);
        } else {
          dispatch(setShowSearchList(false));
          setDisplaySearchUI(false);
          setSearchGameList(null);
        }
      });
    } else {
      dispatch(setShowSearchList(false));
      setDisplaySearchUI(false);
      setSearchGameList(null);
    }
  };

  return (
    <AppBar position="fixed" style={{ zIndex: 1300 }}>
      <header>
        <Toolbar
          className="web-header"
          sx={{
            // backgroundColor: theme.palette.custom.header_bg,
            backgroundColor: theme.palette.custom.black_white,
          }}
          style={{display:"flex",justifyContent:"space-between"}}
        >
          {isAuthenticated && !isFooterRoute ?
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            className="header_bar"
            sx={{
              color: theme.palette.custom.white_red,
            }}
          >
            {/* <MenuIcon /> */}
            <HiOutlineMenuAlt1 />
          </IconButton>
          :""}
          <Typography variant="h6" noWrap>
            <img
              className="header-logo"
              src={logo}
              alt="logo"
              onClick={() => {
                navigate("/");
              }}
            />
          </Typography>

         {isAuthenticated&&isMobile?
          <span
              onClick={() => {
                navigate("/search");
              }}
              style={{marginLeft:"25px"}}
            >  <IoSearch />
            </span>:""}

          {isAuthenticated ? (
            <>
              <TextField
                className="header_search"
                sx={{
                  backgroundColor: theme.palette.custom.search_bg,
                  color: theme.palette.custom.search_color,
                  border: theme.palette.custom.search_border,
                }}
                variant="outlined"
                placeholder="Search..."
                type="search"
                value={search}
                onChange={(e) => {
                  searchGame(e);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setDisplaySearchUI(false);
                    dispatch(setShowSearchList(false));
                  }, 1000);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{
                          color: theme.palette.custom.blue,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <SearchableCardList
                searchGameList={searchGameList}
                visibleSection={displaySearchUI}
              />
              {/* <FormControl
                className="header_dropdown"
                variant="outlined"
                sx={{
                  background: theme.palette.custom.green_gradient,
                }}
              >
                <Select
                  value={value}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    backgroundColor: theme.palette.custom.green_gradient,
                    color: theme.palette.custom.white_gray,
                    border: theme.palette.custom.white_black,
                    ".MuiSelect-icon": {
                      display: "none",
                    },
                  }}
                  disabled
                >
                  <MenuItem value="">
                    {IsLoginData &&
                    IsLoginData?.account?.gc_balance !== undefined
                      ? IsLoginData?.account?.gc_balance.toFixed(2)
                      : 0.0}
                  </MenuItem>
                </Select>
                <img className="coin_select" src={coin} alt="logo" />
                <Button
                  className="add_header"
                  size="small"
                  onClick={() => {
                    navigate("/buy-coins");
                  }}
                  sx={{
                    background: theme.palette.custom.green_dark_gradient,
                    color: theme.palette.custom.white_white,
                  }}
                >
                  <FaPlus />
                </Button>
              </FormControl> */}
              <div className="m-auto header_group_icon">
                <Grid
                  style={{ display: isMobile ? "none" : "" }}
                  container
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: theme.palette.custom.red_light,
                        color: theme.palette.custom.white_red,
                        borderRadius: 4,
                        p: 1,
                        width: "130px",
                        cursor: "pointer",
                      }}
                      onClick={handleProfileClick}
                    >
                      <Avatar sx={{ width: 24, height: 24, mr: 1 }}>
                        <FaUser
                          fontSize="small"
                          style={{ color: theme.palette.custom.white_red }}
                        />
                      </Avatar>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          marginTop: "-4px",
                          textTransform: "capitalize",
                        }}
                      >
                        {IsLoginData?.name}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Menu
                      anchorEl={profileMenuAnchor}
                      open={Boolean(profileMenuAnchor)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      PaperProps={{
                        sx: {
                          width: "180px",
                          overflowY: "auto",
                          marginTop: "5px",
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleNavigation("/profile")}>
                        <Typography textAlign="center">My Profile</Typography>
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={() => handleNavigation("/buy-coins")}>
                        <Typography textAlign="center">Buy Coins</Typography>
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={() => handleNavigation("/contact-us")}>
                        <Typography textAlign="center">
                          Contact Support
                        </Typography>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() =>
                          handleNavigation("/responsible-gaming-settings")
                        }
                      >
                        <Typography textAlign="center">
                          Responsible gaming
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>

                <Button
                  className="msg_header"
                  size="medium"
                  sx={{
                    background: theme.palette.custom.gray_red,
                    border: theme.palette.custom.header_border,
                    color: theme.palette.custom.red_white,
                  }}
                  onClick={logoutClick}
                >
                  <FaSignOutAlt />
                </Button>
              </div>{" "}
            </>
          ) :  <div
          className="top_buttons"
          style={{marginRight: "20px",display:"flex-end",justifyContent:"space-between"}}
        >
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight:20}}
            onClick={() => {
              navigate("/login");
            }}
          >
            <Typography fontWeight={600}>Login</Typography>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate("/signup");
            }}
          >
            <Typography fontWeight={600}>Sign up</Typography>
          </Button>
        </div> }
        </Toolbar>
      </header>
    </AppBar>
  );
};

export default NavBar;
