import React, { useState, useEffect } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { darkTheme, lightTheme } from "./Theme";
import { AuthProvider } from "./AuthContext";
import RouteList from "./RouteList";
import { useSelector } from "react-redux";
import Loader from "./component/Loader";
import { axiosInstance } from "./Utility/axiosInstances";
import { createBrowserHistory } from "history";
import { useDispatch } from "react-redux";
import { setThemeMode } from "./redux/Reducers/GlobalReducer/globalSlice";
import { setLoginData } from "./redux/Reducers/AuthReducer/authSplice";
import { setIsDrawerOpen } from "./redux/Reducers/sidebarReducer/sidebarSlice";
import { BrowserRouter } from "react-router-dom";
import { setCookie } from "./Utility/functions/Helper";
import blurVertical from './images/blur-vertical.png'
import { setDispatch } from "./Utility/ConfigData";
function App() {
  const globalState = useSelector((state) => state.global);
  const { themeMode, loading } = globalState;
  const theme = themeMode === "light" ? lightTheme : darkTheme;
  const history = createBrowserHistory();
  const authState = useSelector((state) => state.auth);
  const { IsLoginData } = authState;
  const dispatch = useDispatch();

  let params = new URLSearchParams(window.location.search);
  let ref = params.get("ref") || "";
  let campaign = params.get("campaign") || "";
  console.log('ref',ref,'campaign',campaign)
  if (ref) {
    setCookie("ref", ref, 30); // Set the cookie to expire in 30 days
  }
  if (campaign) {
    setCookie("campaign", campaign, 30); // Set the cookie to expire in 30 days
  }

  axiosInstance.interceptors.request.use((config) => {
    // config.headers["SessonId"] = IsLoginData.sessionId;
    config.headers["SessonId"] = localStorage.getItem("session");
    return config;
  });

  

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.log("error", error);
      if (error?.response?.status == "401") {
        localStorage.clear();
        window.alert(
          "You are currently logged in on another device. Please login again to continue.  You will be logged out of your other device."
        );
        dispatch(setThemeMode("dark"));
        dispatch(setLoginData({ user: {}, isAuthenticated: false }));
        history.replace("/login");
        sessionStorage.clear();
        window.location.reload();
       
      // window.location.reload();
      }
      return error;
    }
  );

  useEffect(() => {

    setDispatch(dispatch);

  }, []);


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <AuthProvider>
        <Loader loading={loading} />
        <RouteList />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
