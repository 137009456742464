import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";

const Support = () => {
  return (
    <Grid container spacing={3} style={{ padding: 24 }}>
      <Typography variant="h4" component="h2" gutterBottom>
      Support Page
      </Typography>
    </Grid>
  );
};

export default Support;
