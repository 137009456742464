import React from "react";

const ResponsibleGaming = () => {
  return (
    <div>
      <div
        className="container bg-color-rg space-bottom content-only"
        style={{ marginTop: 60, padding: 20, paddingLeft: 35 }}
      >
        <div className="inner-smallspace-rg">
          <div className="row">
            <div className="col-md-12">
              <div className="content-page-design">
                <h2>SpinSaga Responsible Gaming</h2>
                <p>
                  At SpinSaga Inc., we are committed to promoting responsible
                  gaming practices and providing a safe and enjoyable experience
                  for our players. As a sweepstakes-based social casino, we
                  encourage participants to play responsibly, ensuring that
                  gaming remains a form of entertainment and not a source of
                  stress or harm.
                </p>
                <h3>Our Commitment to Responsible Gaming</h3>
                <p>
                  SpinSaga Inc. advises all customers to engage in gaming within
                  their personal means and to treat sweepstakes entries as
                  entertainment, not an investment or financial solution. If you
                  feel that gaming is affecting your well-being, we encourage
                  you to seek help immediately.
                </p>

                <h3>Resources for Help</h3>
                <p>
                  If you or someone you know is struggling with gambling-related
                  issues, please reach out to the following organizations for
                  support:
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>National Council on Problem Gambling</strong>
                      <br />
                      Call: <strong>1-800-522-4700</strong>
                      <br />
                      Visit:{" "}
                      <a href="https://www.ncpgambling.org/" target="a_blank">www.ncpgambling.org</a>
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>1-800-GAMBLER</strong>
                      <br />
                      Call: <strong>1-800-GAMBLER</strong>
                      <br />
                      (Available in multiple states)
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>Gamblers Anonymous</strong>
                      <br />
                      Visit:{" "}
                      <a href="https://www.gamblersanonymous.org/ga/" target="a_blank">
                        www.gamblersanonymous.org/ga
                      </a>
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>
                        GAM-ANON (Support for loved ones of gamblers)
                      </strong>
                      <br />
                      Visit:{" "}
                      <a href="https://www.gam-anon.org/" target="a_blank">
                        www.gam-anon.org
                      </a>
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>National Suicide Prevention Hotline</strong>
                      <br />
                      Call: 1-800-273-8255 / 988 
                      <br />
                      Visit:{" "}
                      <a href="http://www.suicidepreventionlifeline.org/" target="a_blank">
                        www.suicidepreventionlifeline.org
                      </a>
                    </p>
                  </li>
                </ul>

                 <h3>Tools for Responsible Gaming</h3>
                <p>
                  SpinSaga offers tools to help players manage their time and
                  spending, ensuring they stay in control of their gaming
                  experience:
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>Setting Limits</strong>
                      <br />
                      Players can set limits on the total dollar value of
                      sweepstakes entries submitted. These limits are enforced
                      until the completion period and cannot be increased during
                      that time, offering complete control over spending.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>Cool-Off Periods</strong>
                      <br />
                      If you feel the need to take a break, you can activate a
                      Cool-Off period for up to 30 days. During this time, your
                      account will be locked, and you won’t be able to log in.
                      Any sweepstakes entries that are active during this period
                      will remain valid, and any potential winnings will be
                      accessible once the Cool-Off period ends.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong>Self-Exclusion</strong>
                      <br />
                      <p>
                        For those who wish to stop playing entirely, SpinSaga
                        offers a permanent self-exclusion option. Upon
                        self-exclusion:
                      </p>
                      <ul>
                        <li>
                          Your account will be immediately closed, and you will
                          no longer have access.
                        </li>
                        <li>Any open entries will be canceled.</li>
                        <li>
                          Gold Coins and Saga Coins in your account will be
                          revoked, as they have no monetary value.
                        </li>
                      </ul>
                      Players can set limits on the total dollar value of
                      sweepstakes entries submitted. These limits are enforced
                      until the completion period and cannot be increased during
                      that time, offering complete control over spending.
                    </p>
                  </li>

                  <li>
                    <p>
                      Please note that permanent self-exclusion may be reported
                      to state regulatory bodies, where applicable.
                    </p>
                  </li>
                </ol>

                <h3>Need Assistance?</h3>
                <p>
                  We understand that managing gaming activity responsibly can be
                  challenging for some individuals. Our customer support team is
                  here to help.
                </p>
                <ul>
                  <li>
                    <strong>Contact Us:</strong><br/>
                    Email:{" "}
                    <a href="mailto:support@spinsagacasino.com">
                      support@spinsagacasino.com
                    </a>
                  </li>
                </ul>

                <p>
                  An agent will assist you with setting limits, applying a
                  Cool-Off period, or activating a full self-exclusion.
                </p>

                <p>
                  At SpinSaga, our goal is to create a fun, safe, and
                  responsible environment for all players. By utilizing the
                  tools and resources available, we hope to ensure gaming
                  remains a positive and enjoyable experience. Thank you for
                  choosing SpinSaga!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsibleGaming;
